import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Carousel from 'nuka-carousel';

import Layout from "../components/layout"
import SEO from "../components/seo"

const Media = () => {

  const data = useStaticQuery(graphql`
    query Images {
      images: allFile( filter: { relativeDirectory: { eq: "gallery" }})
      {
        nodes {
          id
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }  
  `)

  return (
      <Layout>
      <SEO title="Media" />
      <h1 className="title">MEDIA</h1>
      <div className="mediaSection">
        <Carousel>
        {data.images.nodes.map(image => (
          <Img key={image.id} fluid={image.childImageSharp.fluid} />
        ))}
        </Carousel>
      </div>
      </Layout>
  )
}

export default Media;